import { Box } from '@mui/material';
import React from 'react';
import { COLORS } from '../constants';
import ShowcasePage from './showcase/showcase-page';
import betaDomainModel from '../images/domainModel.png';
import siteMap from '../images/siteMap.png';
import userJourney from '../images/userJourney.png';
import { ImageContainer } from '../components/image-container';
import SectionHeader from './showcase/section-header';
import { Spacing } from '../components/spacing';
import { Text } from '../components/text';
import wireframe from '../images/IA_wireframe_1.png';

export default function DesigningForCommunityBands() {
  return (
    <ShowcasePage>
      <Box component="h1" mb={4} sx={{ color: COLORS.showcase }}>
        Orchestrate
      </Box>
      <Box component="p" mb={2}>
        Designing a community for community bands
      </Box>
      <ImageContainer
        images={[betaDomainModel, siteMap, userJourney]}
        alts={['Domain Model', 'Site Map', 'User Journey']}
        clickable
      />

      <SectionHeader>About</SectionHeader>

      <Box component="p" mb={2}>
        Orchestrate is a website concept I am working on as part of my master's
        program. The aim of Orchestrate is to create an online community for
        amateur musicians. Here, interested musicians can find bands to join or
        instruments to rent or buy, band members can stay updated on band events
        and conversations, band administrators can manage their bands and access
        resources such as funding opportunities and pieces for their next
        concert, and curious music-lovers can learn about community bands, find
        a concert near them, or even donate.
      </Box>

      <Box component="p" mb={2}>
        My main goal in completing this project is to gain a deeper
        understanding of the information architecture design process in digital
        products, including the research process, sitemap development,
        wireframing, and user evaluations. This project is still an active work
        in progress - check back in later to see the finished product!
      </Box>

      <SectionHeader>The Process</SectionHeader>

      <ol>
        <li>
          <Spacing size="small">
            <Text emphasis={COLORS.showcase}>Domain Research</Text> - I started
            out by{' '}
            <Text emphasis="black">interviewing three domain experts</Text> to
            understand more about the topic. These three people had several
            years of music experience and had experience with at least two
            bands. They had also all joined a band within the previous six
            months. These interviews were semi-structured - my goal was to gain
            a clear understanding of the domain and their experiences with
            community bands, but I also wanted to appreciate the unique angle
            each of my interviewees had to offer by keeping the interview
            conversational. Once I had gathered my interview notes, I created a{' '}
            <Text emphasis="black">domain model</Text> to help me visualize the
            main concepts involved and how they related to one another. This
            diagram would serve as a foundation for my sitemap.
          </Spacing>
          <Spacing size="medium">
            <ImageContainer
              images={[betaDomainModel]}
              alts={['Domain model of community band research']}
              limitWidth={600}
              clickable
            />
          </Spacing>
        </li>
        <li>
          <Spacing size="small">
          <Text emphasis={COLORS.showcase}>Mapping the website</Text> - I
          created a <Text emphasis="black">sitemap</Text> for the website
          concept to better visualize the content, how it should be structured,
          and how users would navigate it. I first conducted a{' '}
          <Text emphasis="black">card sort</Text> using key domain concepts that
          would be included in the site with approximately 7 participants from
          my target audience. Using the insights from that test, I built out the
          sitemap, then ran a <Text emphasis="black">tree test</Text> with 12
          participants to ensure that the structure was logical and key
          information was findable.
          </Spacing>
          <Spacing size="medium">
            <ImageContainer
              images={[siteMap]}
              alts={['Site map']}
              limitWidth={600}
              clickable
            />
          </Spacing>
        </li>
        <li>
          <Spacing size="small">
          <Text emphasis={COLORS.showcase}>
            Visualizing the user's experience
          </Text>{' '}
          - Referencing the sitemap, I created a{' '}
          <Text emphasis="black">user journey</Text> to show the various paths a
          user could take to join a community band via my website. This serves
          to demonstrate the flexibility of my site's information structure,
          empathize with the user, and check for any opportunities to simplify
          the information-finding process.
          </Spacing>
          <Spacing size="medium">
            <ImageContainer
              images={[userJourney]}
              alts={['User journey']}
              limitWidth={600}
              clickable
            />
          </Spacing>
        </li>
        <li>
          <Spacing size="small">
          <Text>More wireframes & user evaluations coming soon.</Text>
          </Spacing>
          <ImageContainer images={[wireframe]} alts={['Information architecture wireframe']} limitWidth={600} clickable />>
        </li>
      </ol>
    </ShowcasePage>
  );
}
